import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Col,
  Container,
  Form,
  Row,
  Button,
  Alert,
  Badge,
  Spinner,
} from 'react-bootstrap';
import axios from 'axios';
import { fixNewlineChars } from '../../utils/stringProcessing';
import './InstanceGenerator.css';
import UserContext from '../../context/UserContext';
import { Appl } from '../../interfaces';
import { useParams } from 'react-router-dom';
import { Knowledge } from '../../interfaces';
import { ItemComponentProps } from '@webscopeio/react-textarea-autocomplete';
import CustomReactTextareaAutocomplete from '../../utils/CustomReactTextareaAutocomplete.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getEncoding, encodingForModel } from 'js-tiktoken';
import {
  DEFAULT_TEMPLATE_PROMPT,
  USER_STORY_PROMPT,
} from '../../utils/defaultTemplatePrompts';

import KnowledgeTable from '../KnowledgeIdTable';
import { KNOWLEDGE_IDS, USER_STORY_GENERATOR } from '../../utils/constants';
import Stepper from '../Stepper';
import TrusthWorthyLlmForm from '../TrustworthyLlm';

type InstanceGeneratorProps = {
  currentStep: number;
  projectId: any;
  currentAppId: any;
  nextStep: any;
  setShowNewInstanceModal: any;
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  isUploadtemplate?: boolean;
  isEditMode?: any;
  instanceDetails?: any;
  selectedInstance?: any;
  prevStep: any;
  isBackClicked?: boolean;
  getInstanceData?: any;
};

interface KeyValue {
  key: string;
  value: string;
}

const InstanceGenerator: React.FC<InstanceGeneratorProps> = ({
  currentStep,
  projectId,
  currentAppId,
  nextStep,
  prevStep,
  setShowNewInstanceModal,
  step1 = true,
  step2 = true,
  step3 = true,
  step4 = true,
  isUploadtemplate = false,
  isEditMode,
  instanceDetails,
  selectedInstance,
  isBackClicked,
  getInstanceData,
}) => {
  const { appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [knowledgeIDs, setKnowledgeIDs] = useState<any[]>([]);
  const [currentKnowledgeID, setCurrentKnowledgeID] = useState<any[]>([]);
  const [newKnowledgeID, setNewKnowledgeID] = useState<string>('');
  const [newTemplateName, setNewTemplateName] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<number>(0.0);
  const [chunkSizeValue, setChunkSizeValue] = useState<number>(5);
  const [kNearChunksValue, setKNearChunksValue] = useState<number>(6);
  const [instanceName, setInstanceName] = useState<string>('');
  const [flowId, setFlowid] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [templatePrompt, setTemplatePrompt] = useState<string>('');
  const [totalSteps, setTotalSteps] = useState<number>();
  const [fileId, setFileId] = useState<any[]>([]);
  const [showFileUploadSuccessMsg, setShowFileUploadSuccessMsg] =
    useState<Boolean>(false);
  const filesSelectedForUploadRef = useRef<HTMLInputElement>(null);
  const knowledgeFileUpload = useRef<HTMLInputElement>(null);
  const [chunkingStrategy, setChunkingStrategy] = useState('TOKEN_LIMIT_CHUNK');
  const [keyValues, setKeyValues] = useState<KeyValue[]>([
    { key: '', value: '' },
  ]);
  const [instanceData, setInstanceData] = useState<any>(null);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('Automatic');
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const [renderMarkdown, setRenderMarkdown] = useState('No');
  // const [llmGuardOption, setLlmGuardOption] = useState('No');
  const [cloudProviderOptions, setCloudProviderOptions] = useState<any>([]);
  const [selectedModel, setSelectedModel] = useState<any>('');
  const [tokencount, setTokencount] = useState<number>(0);
  const [filetokencount, setFiletokencount] = useState<number>(0);
  const [knowledgeidAdded, setKnowlwdgeidAdded] = useState<boolean>(false);
  const [tokelimit, settokenlimit] = useState<any>();
  const [documentContents, setDocumentContents] = useState<string | null>(null);
  const [count, setCount] = useState(0);
  const [models, setModels] = useState([
    {
      name: 'azure',
      models: [
        {
          modelName: 'GPT-4 Turbo 128K',
          modelValue: 'gpt-4-turbo',
          tokenSize: 126000,
        },
        { modelName: 'GPT-4o 128K', modelValue: 'gpt-4o', tokenSize: 126000 },
        { modelName: 'GPT-4 32K', modelValue: 'gpt4-32k', tokenSize: 30768 },
        { modelName: 'GPT-3.5 16K', modelValue: 'gpt35-16k', tokenSize: 14384 },
      ],
    },
  ]);
  const [uploadError, setUploadError] = useState(false);
  const userContext = useContext(UserContext) || {
    showToast: false,
    setShowToast: () => {},
    showErrMsg: '',
    setShowErrMsg: () => {},
    cloudProvider: 'azure',
    setCloudProvider: () => {},
    setSelectedInstance: () => {},
    llmGuardOption: '',
    setLlmGuardOption: () => {},
  };

  const {
    setShowToast,
    setShowErrMsg,
    cloudProvider,
    setCloudProvider,
    setSelectedInstance,
    llmGuardOption,
    setLlmGuardOption,
  } = userContext;
  const Item: React.FC<ItemComponentProps<Knowledge>> = ({
    entity: { id, name },
  }) => <div>{`${id}: ${name}`}</div>;
  const Loading: any = ({}) => <div>Loading..</div>;

  useEffect(() => {
    setKnowledgeIDs(instanceDetails?.knowledgeIds);
  }, [instanceDetails]);

  const addKnowledgeID = async (id: string) => {
    setKnowledgeIDs([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/knowledge/flow/${isEditMode ? instanceDetails.flow : id}`
      );

      if (response.status === 200) {
        setShowToast(false);
        const responseData = response.data;
        setKnowledgeIDs(responseData?.response);
        getInstanceData(isEditMode ? selectedInstance : instanceData?.id);
        setIsUploadLoading(false);
        setIsUploadSuccess(true);
        return responseData;
      } else {
        console.error('Get request failed');
        setIsUploadLoading(false);
        return;
      }
    } catch (error) {
      setShowToast(true);
      console.error('Error:', error);
      setIsUploadLoading(false);
      return;
    }
  };

  const handleTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewTemplateName(event.target.value);
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setSliderValue(newValue);
  };

  const handleChunkSizeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    setChunkSizeValue(newValue);
  };

  const handleKNearChunksChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    setKNearChunksValue(newValue);
  };

  const handleInstanceCreation = async (payload: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/create-instance`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        const instanceData = responseData?.response;
        setSelectedInstance(instanceData?.id);
        setInstanceData(instanceData);
        setFlowid(instanceData?.flow);
        nextStep();
        setNewTemplateName(instanceName);
        // setNewKnowledgeID(instanceName);
        if (currentApp?.name === 'Change Communicator') {
          getInstanceData(selectedInstance);
          setShowNewInstanceModal(false);
        }
        return responseData;
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };

  const createInstance = () => {
    const payload = {
      llm_model: selectedModel,
      name: instanceName,
      projectId: projectId,
      temperature: sliderValue,
      applicationId: currentAppId,
      chunkSize: chunkSizeValue,
      chunkingStrategy: step3 ? chunkingStrategy : undefined,
      kNearChunks: kNearChunksValue,
      cloud_environment: cloudProvider ? cloudProvider : 'azure',
      render_markdown: renderMarkdown === 'Yes' ? true : false,
      llm_guard: llmGuardOption === 'Yes' ? true : false,
    };
    const resData = handleInstanceCreation(payload);
    if (Object.keys(resData)?.length) {
    }
  };

  const fetchInstance = () => {
    nextStep();
    // if (!step4) {
    setShowNewInstanceModal(false);
    // }
  };

  const handleInstanceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstanceName(event.target.value);
  };
  const [uploadcount, setUploadCount] = useState<number>(0);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadLoading(false);
    setIsUploadSuccess(false);
    const files = event.target.files;
    if (files) {
      const uploadedFiles = Array.from(files).filter((file) => {
        // Check for CSV extension (case-insensitive)
        const extension = file.name.split('.').pop()?.toLowerCase();
        return extension === 'csv';
      });
      if (newKnowledgeID.length == 0) {
        setUploadError(true);
        setUploadCount(1);
      }
      setUploadedFiles(Array.from(files));
    }
  };

  const loadKnowledge = async () => {
    setIsUploadLoading(true);
    setIsUploadSuccess(false);
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });
    try {
      const queryParams = {
        knowledge_name: newKnowledgeID ? newKnowledgeID : instanceDetails.name,
        flow_id: isEditMode ? instanceDetails.flow : flowId,
        chunking_strategy: step3
          ? isEditMode
            ? instanceDetails.chunking_strategy
            : chunkingStrategy
          : undefined,
      };
      let url = '';
      const azure_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/load`;
      const azure_ocr_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/load-with-ocr`;
      // const azure_image_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/load-images-with-ocr`;
      if (isEditMode) {
        if (instanceDetails.cloudEnvironment === 'azure') {
          if (documentContents === 'tables-or-images') {
            url = azure_ocr_api;
          } else {
            url = azure_api;
          }
        }
      } else {
        if (cloudProvider === 'azure') {
          if (documentContents === 'tables-or-images') {
            url = azure_ocr_api;
          } else {
            url = azure_api;
          }
        }
      }
      const response = await axios.post(url, formData, {
        params: queryParams,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        setUploadedFiles([]);
        setUploadCount(0);
        setUploadError(false);
        setNewKnowledgeID('');
        setKnowlwdgeidAdded(true);
        setDocumentContents(null);
        if (knowledgeFileUpload && knowledgeFileUpload?.current) {
          knowledgeFileUpload.current.value = '';
        }
        const responseData = response.data;
        const knowledgeArray = [responseData.knowledge];
        const transformedKnowledge: Knowledge[] =
          knowledgeArray.map((item: Knowledge) => ({
            id: item.id,
            name: item.name,
          })) || [];
        setCurrentKnowledgeID((prevKnowledge) => [
          ...prevKnowledge,
          ...transformedKnowledge,
        ]);
        addKnowledgeID(flowId);
      } else {
        setShowToast(true);
        setIsUploadLoading(false);
      }
    } catch (error: any) {
      const err = error?.response?.data;
      setShowToast(true);
      setShowErrMsg(err?.detail);
      setIsUploadLoading(false);
      setDocumentContents(null);
      console.error('Error:', error);
    }
  };

  const handleCreateTemplate = async (payload: any) => {
    try {
      let templateApi = '';
      let response;
      if (isEditMode) {
        templateApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/template/flow`;
        response = await axios.put(templateApi, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        templateApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/template`;
        response = await axios.post(templateApi, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        getInstanceData(selectedInstance);
        nextStep();
        setShowNewInstanceModal(false);
        setCurrentKnowledgeID([]);
        return responseData;
      } else {
        setShowToast(true);
        return;
      }
    } catch (error: any) {
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err?.errorMessage);
      console.error('Error:', error);
      return;
    }
  };

  const createTemplate = () => {
    const knowledgeIdsInPrompt = templatePrompt
      ?.match(/{{knowledgeid_(\w+)}}/g)
      ?.map((match) => match.substring(14, match.length - 2));
    const allKnowledgeIds = [
      ...(knowledgeIDs?.map((item) => item.id) ?? []),
      ...(newKnowledgeIds?.map((item) => item.id) ?? []),
      ...(instanceDetails.knowledgeIds?.map((item: any) => item.id) ?? []),
    ];
    const missingIds = knowledgeIdsInPrompt
      ? knowledgeIdsInPrompt.filter(
          (id) => !allKnowledgeIds.includes(parseInt(id))
        )
      : [];
    if (missingIds.length > 0) {
      setShowErrMsg(
        `The Knowledge ID(s) - ${missingIds} referenced in the system template are either incorrect or deleted. Please correct to proceed further.`
      );
      setShowToast(true);
      return;
    }

    let payload;
    const inputTextPlaceholder = '{{input_text}}';
    const newline = '\n';
    if (isEditMode) {
      payload = {
        flow_id: instanceDetails.flow,
        template_id: instanceDetails.templateId,
        prompt_template: templatePrompt
          ? templatePrompt.includes(inputTextPlaceholder)
            ? templatePrompt
            : templatePrompt + newline + inputTextPlaceholder
          : instanceDetails.template.includes(inputTextPlaceholder)
            ? instanceDetails.template
            : instanceDetails.template + newline + inputTextPlaceholder,
        temperature: sliderValue,
      };
    } else {
      payload = {
        name: newTemplateName ? newTemplateName : instanceDetails.templateName,
        flow: flowId,
        promptTemplate: fixNewlineChars(
          templatePrompt.includes(inputTextPlaceholder)
            ? templatePrompt
            : templatePrompt + newline + inputTextPlaceholder
        ),
        doc_template_ids: fileId,
      };
    }
    handleCreateTemplate(payload);
  };

  useEffect(() => {
    //code for llm guard
    if (step1 && step2 && step3 && step4) {
      setTotalSteps(4);
    } else if (step1 && step2 && step4) {
      setTotalSteps(3);
    } else if (step1 && step3 && step4) {
      setTotalSteps(3);
    } else if (step1 && step2 && step3) {
      setTotalSteps(3);
    } else if (step1 && (step3 || step4)) {
      setTotalSteps(2);
    } else {
      setTotalSteps(1);
    }
  }, [step1, step2, step3, step4]);

  useEffect(() => {
    if (chunkingStrategy === 'SPACY_SENT_CHUNK') {
      setChunkSizeValue(10);
      setKNearChunksValue(8);
    } else if (chunkingStrategy === 'TOKEN_LIMIT_CHUNK') {
      setChunkSizeValue(1500);
      setKNearChunksValue(6);
    } else if (chunkingStrategy === 'CUSTOM_PARA_CHUNK') {
      setChunkSizeValue(100);
      setKNearChunksValue(10);
    } else if (chunkingStrategy === 'PAGE_WISE_CHUNK') {
      setChunkSizeValue(1);
      setKNearChunksValue(6);
    } else if (chunkingStrategy === 'LANGCHAIN_SENT_CHUNK') {
      setChunkSizeValue(25);
      setKNearChunksValue(15);
    } else if (chunkingStrategy === 'LANGCHAIN_PARA_CHUNK') {
      setChunkSizeValue(400);
      setKNearChunksValue(15);
    }
  }, [chunkingStrategy]);

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsUploadSuccess(false);
    }, 2000);

    return () => clearTimeout(removeSuccessMessage);
  }, [isUploadSuccess]);

  useEffect(() => {
    if (isEditMode) {
      setChunkSizeValue(instanceDetails.chunkSize);
      setKNearChunksValue(instanceDetails.kNearChunks);
      setSliderValue(instanceDetails.temperature);
    }
  }, [isEditMode, instanceDetails]);
  useEffect(() => {
    let count = 0;
    if (isEditMode) {
      if (instanceDetails && instanceDetails.knowledgeIds.length) {
        for (let i = 0; i < instanceDetails.knowledgeIds.length; i++) {
          count =
            count +
            templatePrompt.split(instanceDetails.knowledgeIds[i].id).length -
            1;
        }
      }

      if (knowledgeIDs.length > 0) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count = count + templatePrompt.split(knowledgeIDs[i].id).length - 1;
        }
        // count=count-1;
      }
      count = count;
      const knowledgetoken =
        (count / 2) * instanceDetails.chunkSize * instanceDetails.kNearChunks;

      setFiletokencount(knowledgetoken);
    }

    if (!isEditMode) {
      if (knowledgeIDs) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count = count + templatePrompt.split(knowledgeIDs[i].id).length - 1;
        }
      }
      const knowledgetoken = count * chunkSizeValue * kNearChunksValue;
      setFiletokencount(knowledgetoken);
    }
  }, [selectedModel]);
  useEffect(() => {
    if (isEditMode) {
      instanceDetails.llmGuard
        ? setLlmGuardOption('Yes')
        : setLlmGuardOption('No');
      instanceDetails.renderMarkdown
        ? setRenderMarkdown('Yes')
        : setRenderMarkdown('No');
    }
  }, [isEditMode, instanceDetails.llmGuard, instanceDetails.renderMarkdown]);

  const handleUploadInstanceDoc = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/template/document/submit`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setShowToast(false);
        setUploadedFiles([]);
        const responseData = response.data;
        const data: Array<string> = [];
        const res = responseData.response;
        res.forEach((item: any) => {
          data.push(item.id);
          setFileId(data);
        });
        setShowFileUploadSuccessMsg(true);
        if (filesSelectedForUploadRef?.current) {
          filesSelectedForUploadRef.current.value = '';
        }
      } else {
        setShowToast(true);
      }
    } catch (error: any) {
      setShowToast(true);
      console.error('Error:', error);
      const err = error?.response?.data;
      if (err && err.error) {
        setShowErrMsg(err.error.errorMessage);
      }
    }
  };

  const handleKnowledgeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setUploadError(false);
    }
    if (event.target.value.length === 0 && uploadcount == 1) {
      setUploadError(true);
    }
    setNewKnowledgeID(event.target.value);
  };
  useEffect(() => {
    setUploadError(false);
  }, [currentStep]);
  useEffect(() => {
    if (isEditMode && instanceDetails && instanceDetails.template) {
      setTemplatePrompt(instanceDetails.template.replace(/\\n/g, '\n'));
    }
  }, [instanceDetails, isEditMode]);

  useEffect(() => {
    if (!isEditMode && currentApp && !templatePrompt.length) {
      switch (currentApp?.name) {
        case USER_STORY_GENERATOR:
          setTemplatePrompt(USER_STORY_PROMPT);
          break;
        default:
          setTemplatePrompt(DEFAULT_TEMPLATE_PROMPT);
          break;
      }
    }
  }, []);
  useEffect(() => {
    if (isEditMode && instanceDetails) {
      const selectedCp = models.filter((cp) => {
        if (cp.name === instanceDetails.cloudEnvironment) return cp;
      });
      const cpOptions = selectedCp[0].models;
      setCloudProviderOptions(cpOptions);
      setSelectedModel(cpOptions[0].modelValue);
      if (instanceDetails.llmModel) {
        setSelectedModel(instanceDetails.llmModel);
      }
    }
  }, [instanceDetails]);

  useEffect(() => {
    if (isEditMode && instanceDetails) {
      const selectedCp = models.filter((cp) => {
        if (cp.name === instanceDetails.cloudEnvironment) return cp;
      });
      const cpOptions = selectedCp[0].models;
      setCloudProviderOptions(cpOptions);
      setSelectedModel(cpOptions[0].modelValue);
      if (instanceDetails.llmModel) {
        setSelectedModel(instanceDetails.llmModel);
      }
    } else {
      const selectedCp = models.filter((cp) => {
        if (cp.name === cloudProvider) return cp;
      });
      const cpOptions = selectedCp[0].models;
      setCloudProviderOptions(cpOptions);
      settokenlimit(cpOptions[0]);
      // setSelectedModel("GPT-3.5 Turbo");
    }
  }, [cloudProvider]);
  useEffect(() => {
    if (!isEditMode && selectedModel === '') {
      setSelectedModel('gpt-4-turbo');
    }
    if (!isEditMode && !(selectedModel === '')) {
      const selectedCp = cloudProviderOptions.filter((cp: any) => {
        if (cp.modelName === selectedModel || cp.modelValue === selectedModel)
          return cp.tokenSize;
      });
      setSelectedModel(selectedCp[0].modelValue);
      settokenlimit(selectedCp[0]);
    }
  }, [instanceName]);
  useEffect(() => {
    if (!isEditMode) {
      const enc = getEncoding('gpt2');

      let newValue = templatePrompt
        ?.replace(/\{[\s\{]*\{/g, '{{')
        .replace(/\}[\s\}]*\}/g, '}}');
      // AutoComplete Bug solution- Jira ticket no. 500
      newValue = newValue.replace(/}}([^{]+}}?)/g, '}}');
      let newValue1 = enc.encode(newValue); // Encode the text using gpt2 encoding
      setTokencount(newValue1.length);
    }
  }, [instanceName]);

  useEffect(() => {
    cloudProvidrModelChange();
  }, [selectedModel, cloudProviderOptions, currentStep]);

  const cloudProvidrModelChange = () => {
    const selectedCp = cloudProviderOptions.filter((cp: any) => {
      if (cp.modelName === selectedModel || cp.modelValue === selectedModel)
        return cp.tokenSize;
    });
    settokenlimit(selectedCp[0]);
  };
  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowIndex)) {
        newExpandedRows.delete(rowIndex);
      } else {
        newExpandedRows.add(rowIndex);
      }
      return newExpandedRows;
    });
  };

  const newKnowledgeIds =
    knowledgeIDs &&
    knowledgeIDs.filter(
      (knowledgeID) =>
        !instanceDetails.knowledgeIds?.some(
          (instanceDetail: any) => instanceDetail.id === knowledgeID.id
        )
    );

  const editTemperature = async () => {
    let payload;
    payload = {
      llm_model: selectedModel,
      temperature: sliderValue,
      llm_guard: llmGuardOption === 'Yes' ? true : false,
      render_markdown: renderMarkdown === 'Yes' ? true : false,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/update/temperature-markdown-llmguard/${selectedInstance}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        nextStep();
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      console.error(
        'Error Editing instance',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };

  const handleoautomaticmanualptionchange = (event: any) => {
    setSelectedOption(event.target.checked ? 'Manual' : 'Automatic');
    if (!isEditMode) {
      setRenderMarkdown('No');
      setLlmGuardOption('No');
      setSliderValue(0.0);
      if (chunkingStrategy === 'SPACY_SENT_CHUNK') {
        setChunkSizeValue(10);
        setKNearChunksValue(8);
      }
      if (chunkingStrategy === 'TOKEN_LIMIT_CHUNK') {
        setChunkSizeValue(1500);
        setKNearChunksValue(6);
      }
      if (chunkingStrategy === 'CUSTOM_PARA_CHUNK') {
        setChunkSizeValue(100);
        setKNearChunksValue(10);
      }
      if (chunkingStrategy === 'PAGE_WISE_CHUNK') {
        setChunkSizeValue(1);
        setKNearChunksValue(6);
      }
      if (chunkingStrategy === 'LANGCHAIN_SENT_CHUNK') {
        setChunkSizeValue(25);
        setKNearChunksValue(15);
      }
      if (chunkingStrategy === 'LANGCHAIN_PARA_CHUNK') {
        setChunkSizeValue(400);
        setKNearChunksValue(15);
      }
    }
  };
  const handlemodelselectchange = (event: any) => {
    const selectedCp = cloudProviderOptions.filter((cp: any) => {
      if (
        cp.modelName === event.target.value ||
        cp.modelValue === event.target.value
      )
        return cp.tokenSize;
    });
    setSelectedModel(event.target.value);
    settokenlimit(selectedCp[0]);
  };

  const getColor = (percentage: any) => {
    if (percentage <= 75) {
      return 'success'; // Green
    } else if (percentage < 100) {
      return 'warning'; // Yellow
    } else {
      return 'danger'; // Red
    }
  };

  function handleTemplatePromptChange(
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    let count = 0;
    if (isEditMode) {
      if (instanceDetails.knowledgeIds.length) {
        for (let i = 0; i < instanceDetails.knowledgeIds.length; i++) {
          count =
            count +
            event.target.value.split(instanceDetails.knowledgeIds[i].id)
              .length -
            1;
        }
      }

      if (knowledgeIDs.length > 0) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count =
            count + event.target.value.split(knowledgeIDs[i].id).length - 1;
        }
        // count=count-1;
      }
      count = count;
      const knowledgetoken =
        (count / 2) * instanceDetails.chunkSize * instanceDetails.kNearChunks;

      setFiletokencount(knowledgetoken);
    }

    if (!isEditMode) {
      if (knowledgeIDs) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count =
            count + event.target.value.split(knowledgeIDs[i].id).length - 1;
        }
      }
      const knowledgetoken = count * chunkSizeValue * kNearChunksValue;
      setFiletokencount(knowledgetoken);
    }

    // Replace '{ {' with '{{' and '} }' with '}}'
    const enc = getEncoding('gpt2');

    let newValue = event.target.value
      ?.replace(/\{[\s\{]*\{/g, '{{')
      .replace(/\}[\s\}]*\}/g, '}}');
    // AutoComplete Bug solution- Jira ticket no. 500
    newValue = newValue.replace(/}}([^{]+}}?)/g, '}}');
    let newValue1 = enc.encode(newValue); // Encode the text using gpt2 encoding
    setTokencount(newValue1.length);
    let newValue2 = enc.decode(newValue1);
    setTemplatePrompt(newValue);
  }
  const cloudProviderSelectChange = (event: any) => {
    setCloudProvider(event.target.value);
    const selectedCp = models.filter((cp) => {
      if (cp.name === event.target.value) return cp;
    });
    const cpOptions = selectedCp[0].models;
    setSelectedModel(cpOptions[0].modelValue);
  };

  const handleDocumentContentsChange = (count: any) => {
    if (count % 2 == 0) {
      setDocumentContents('tables-or-images');
    } else {
      setDocumentContents('');
    }
    setCount(count + 1);
  };

  return (
    <div>
      <Stepper currentStep={currentStep} totalSteps={totalSteps} />
      {
        currentStep === 1 && step1 ? (
          <>
            <Form.Group
              className="instance-name"
              controlId="exampleForm.instanceName"
            >
              <div className="modal-header-container">
                <h2 className="modal-header-title">Basic Details</h2>
              </div>

              <div className="basic-details-container">
                <div className="basic-details-wrapper">
                  <Form.Label className="label-text">Instance Name</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    value={isEditMode ? instanceDetails.name : instanceName}
                    onChange={handleInstanceNameChange}
                    placeholder="Enter Instance Name"
                    className="text-input"
                    disabled={isEditMode || isBackClicked}
                  />
                </div>
              </div>
              <>
                <div className="basic-details-container">
                  <div className="cloudprovider-model-selector">
                    <div className="basic-details-wrapper cloudprovider-model-selector-width">
                      <Form.Label className="label-text">
                        Cloud Provider
                      </Form.Label>
                      <div className="cloudprovider-options">
                        <Form.Select
                          aria-label="Select Cloud Provider"
                          onChange={(evt) => cloudProviderSelectChange(evt)}
                          value={
                            isEditMode
                              ? instanceDetails.cloudEnvironment
                              : cloudProvider
                          }
                          disabled={isEditMode || isBackClicked}
                          className="model-selector"
                        >
                          <option value="azure">Azure</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="basic-details-wrapper cloudprovider-model-selector-width gap">
                      <Form.Label className="label-text">Model</Form.Label>
                      <Form.Select
                        className="model-selector"
                        value={selectedModel}
                        onChange={(event) => handlemodelselectchange(event)}
                      >
                        {cloudProviderOptions.map((models: any) => (
                          <option value={models.modelValue}>
                            {models.modelName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                  {/* <div>
                    <Form.Text>
                      * Upcoming Cloud Provider: GCP and AWS will be available
                      soon. Please refrain from selecting it while creating an
                      instance.
                    </Form.Text>
                  </div> */}
                </div>

                <div className="basic-details-container">
                  <div style={{ fontWeight: 'bold' }} className="option-labels">
                    <span
                      className={
                        selectedOption === 'Manual' ? 'disable-text' : ''
                      }
                    >
                      Automatic
                    </span>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={selectedOption === 'Manual'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleoautomaticmanualptionchange(event)
                      }
                    />
                    <span
                      className={
                        selectedOption === 'Manual' ? '' : 'disable-text'
                      }
                    >
                      Manual
                    </span>
                  </div>
                </div>
              </>
              {selectedOption === 'Manual' && (
                <div className="basic-details-container">
                  <div className="basic-details-wrapper">
                    <Form.Group controlId="formSlider">
                      {' '}
                      <div className="custom-slider-wrapper">
                        <span className="precise-label">Precise</span>
                        <span className="creative-label">Creative</span>
                      </div>
                      <Form.Range
                        value={sliderValue}
                        min={0.0}
                        max={1.0}
                        step={0.1}
                        onChange={handleSliderChange}
                        className="custom-slider"
                      />
                    </Form.Group>
                  </div>
                  {currentApp?.name !== 'Change Communicator' && (
                    <>
                      <div className="basic-details-wrapper">
                        <Form.Label className="label-text">
                          Markdown Format Response
                        </Form.Label>
                        <div
                          style={{ fontWeight: 'bold' }}
                          className="markdown-option-labels"
                        >
                          <span
                            className={
                              renderMarkdown === 'Yes' ? 'disable-text' : ''
                            }
                          >
                            No
                          </span>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={renderMarkdown === 'Yes'}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setRenderMarkdown(
                                event.target.checked ? 'Yes' : 'No'
                              );
                            }}
                          />
                          <span
                            className={
                              renderMarkdown === 'Yes' ? '' : 'disable-text'
                            }
                          >
                            Yes
                          </span>
                        </div>
                      </div>
                      <div className="basic-details-wrapper">
                        <Form.Label className="label-text label-container">
                          LLM Guard{' '}
                          <p className="llm-guard-upcoming-feature-text">
                            ** Upcoming Feature
                          </p>
                        </Form.Label>
                        <div
                          style={{ fontWeight: 'bold' }}
                          className="markdown-option-labels"
                        >
                          <span
                            className={
                              llmGuardOption === 'Yes' ? 'disable-text' : ''
                            }
                          >
                            No
                          </span>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={llmGuardOption === 'Yes'}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLlmGuardOption(
                                event.target.checked ? 'Yes' : 'No'
                              );
                            }}
                          />
                          <span
                            className={
                              llmGuardOption === 'Yes' ? '' : 'disable-text'
                            }
                          >
                            Yes
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {selectedOption === 'Manual' &&
                step3 &&
                currentApp?.name !== 'Notice Analysis Engine' && (
                  <>
                    <div className="basic-details-container">
                      <div className="basic-details-wrapper">
                        <Form.Label className="label-text">
                          Chunking Strategy
                        </Form.Label>
                        <Form.Select
                          aria-label="Select Chunking strategy"
                          onChange={(evt) => {
                            setChunkingStrategy(evt.target.value);
                          }}
                          value={
                            isEditMode
                              ? instanceDetails.chunking_strategy
                              : chunkingStrategy
                          }
                          disabled={isEditMode || isBackClicked}
                        >
                          <option value="SPACY_SENT_CHUNK">
                            Sentence chunking (SpaCy)
                          </option>
                          <option value="CUSTOM_PARA_CHUNK">
                            Paragraph chunking (Custom)
                          </option>
                          <option value="TOKEN_LIMIT_CHUNK">
                            Token chunking
                          </option>
                          <option value="PAGE_WISE_CHUNK">
                            Page-wise Chunking
                          </option>
                          <option value="LANGCHAIN_SENT_CHUNK">
                            Sentence chunking (LangChain)
                          </option>
                          <option value="LANGCHAIN_PARA_CHUNK">
                            Paragraph chunking (LangChain)
                          </option>
                        </Form.Select>
                      </div>
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'SPACY_SENT_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={10}
                                max={25}
                                step={1}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={8}
                                max={40}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'TOKEN_LIMIT_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={1000}
                                max={3000}
                                step={100}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={6}
                                max={15}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'CUSTOM_PARA_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={100}
                                max={200}
                                step={5}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={8}
                                max={30}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'PAGE_WISE_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={1}
                                max={3}
                                step={1}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={4}
                                max={8}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'LANGCHAIN_SENT_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={25}
                                max={50}
                                step={1}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={15}
                                max={30}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'LANGCHAIN_PARA_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={400}
                                max={600}
                                step={20}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={15}
                                max={30}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

              <div className="button-container">
                {currentApp?.name !== 'Change Communicator' && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      isEditMode || isBackClicked
                        ? editTemperature()
                        : createInstance()
                    }
                    className="primary-btn-style modal-button"
                  >
                    Next
                  </Button>
                )}
                {currentApp?.name === 'Change Communicator' && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      isEditMode || isBackClicked
                        ? editTemperature()
                        : createInstance()
                    }
                    className="primary-btn-style modal-button"
                  >
                    Finish
                  </Button>
                )}
              </div>
            </Form.Group>
          </>
        ) : currentStep === 2 && step2 ? (
          <>
            <TrusthWorthyLlmForm
              instanceData={isEditMode ? selectedInstance : instanceData?.id}
              nextStep={nextStep}
              prevStep={prevStep}
              setShowNewInstanceModal={setShowNewInstanceModal}
              instanceDetails={instanceDetails}
            />
          </>
        ) : (!step2 && currentStep === 2 && step3) ||
          (step2 && currentStep === 3 && step3) ? (
          <Container>
            <div className="modal-header-container">
              <h2 className="modal-header-title">Knowledge Details</h2>
            </div>
            <div className="basic-details-container-step2">
              <div className="basic-details-knowledge-wrapper">
                <Form.Group
                  className="knowledge-name"
                  controlId="exampleForm.knowledgeName"
                >
                  <Form.Label className="label-text">Knowledge Name</Form.Label>
                  <Form.Control
                    as="input"
                    // value={isEditMode ? instanceDetails.name : newKnowledgeID}
                    value={newKnowledgeID}
                    className="text-input"
                    onChange={handleKnowledgeName}
                    // disabled={isEditMode}
                  />
                </Form.Group>
              </div>
              <div className="basic-details-knowledge-wrapper">
                <Form.Group
                  className="file-input"
                  controlId="exampleForm.fileInput"
                >
                  <Form.Label className="label-text">
                    Upload Document
                  </Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    accept=".csv, .xlsx, .pdf, .docx, .txt"
                    onChange={handleFileChange}
                    ref={knowledgeFileUpload}
                  />
                  {isUploadLoading && (
                    <div className="upload-spinner">
                      <Spinner animation="border" size="sm" /> Uploading
                    </div>
                  )}
                  {uploadedFiles && isUploadSuccess && (
                    <div className="upload-msg">
                      <Badge bg="success">Upload Successful</Badge>
                    </div>
                  )}
                  <div className="basic-details-container-checkbox">
                    {(cloudProvider === 'azure' ||
                      instanceDetails.cloudEnvironment === 'azure') && (
                      <Form.Group>
                        <Form.Label>
                          The document being uploaded contains :{' '}
                        </Form.Label>
                        <Form.Check
                          type="checkbox"
                          name="documentContents"
                          label="Tables Or Images"
                          value="tables-or-images"
                          checked={documentContents === 'tables-or-images'}
                          defaultChecked={false}
                          onChange={() => handleDocumentContentsChange(count)}
                        />
                      </Form.Group>
                    )}
                  </div>
                  <div className="choose-file-note">
                    Note: 1. Formats supported: pdf, doc, docx, txt, xls, xlsx &
                    csv
                  </div>
                  <div className="choose-file-note">
                    2. To ensure accurate results, please upload documents
                    containing tables in pdf format only. If documents are
                    uploaded in other formats (such as doc, docx, or txt), the
                    content within tables may not be recognized correctly.
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="basic-details-container">
              <Button
                variant="secondary"
                onClick={loadKnowledge}
                className="secondary-btn-style modal-button"
                disabled={uploadError}
              >
                Upload
              </Button>
            </div>
            {uploadError && newKnowledgeID.length <= 0 && (
              <div className="upload-error">
                Knowledge Name is a mandatory field. Please enter the required
                Knowledge Name to proceed further
              </div>
            )}
            <Form.Label className="label-text">{KNOWLEDGE_IDS}</Form.Label>
            <div className="basic-details-container">
              <KnowledgeTable
                data={
                  instanceDetails ? instanceDetails.knowledgeIds : knowledgeIDs
                }
                expandedRows={expandedRows}
                toggleRowExpansion={toggleRowExpansion}
                getInstanceData={getInstanceData}
                showDelete={true}
                cloudProvider={
                  instanceDetails
                    ? instanceDetails.cloudEnvironment
                    : cloudProvider
                }
                selectedInstance={
                  instanceDetails ? selectedInstance : instanceData?.id
                }
              />
            </div>
            <div className="two-button-container">
              <Button
                variant="primary"
                onClick={() => {
                  setCount(0);
                  setDocumentContents('');
                  prevStep();
                }}
                className="secondary-btn-style modal-button"
              >
                Back
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setCount(0);
                  setDocumentContents('');
                  nextStep();
                }}
                className="primary-btn-style modal-button"
                // disabled={uploadError}
              >
                Next
              </Button>
            </div>
          </Container>
        ) : (
          ((!step2 && currentStep === 3 && step4) ||
            (!step3 && currentStep === 3 && step4) ||
            (currentStep === 4 && step4) ||
            (!step3 && currentStep === 2 && step4)) && (
            <Container>
              <div className="modal-header-container">
                <h2 className="modal-header-title">Template Details</h2>
              </div>
              <Form.Group
                className="template-container"
                controlId="exampleForm.createTemplate"
              >
                <div className="template-field-wrapper">
                  <Form.Label className="label-text">Template Name</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    value={
                      isEditMode
                        ? instanceDetails.templateName
                        : newTemplateName
                    }
                    onChange={handleTemplateNameChange}
                    className="text-input"
                    disabled={isEditMode || isBackClicked}
                  />
                </div>
                <div className="template-field-wrapper">
                  <Form.Label className="label-text">
                    Template Instructions
                  </Form.Label>
                  <CustomReactTextareaAutocomplete
                    className="form-control rta__textarea"
                    rows={3}
                    value={templatePrompt}
                    minChar={0}
                    onChange={handleTemplatePromptChange}
                    loadingComponent={Loading}
                    trigger={{
                      '{': {
                        dataProvider: () => {
                          if (instanceDetails) {
                            return [...instanceDetails.knowledgeIds];
                          } else {
                            return knowledgeIDs || [];
                          }
                        },
                        component: Item,
                        output: (item: Knowledge, trigger: string) =>
                          `{{knowledgeid_${item.id}}}`,
                      },
                    }}
                  />

                  {isEditMode &&
                    instanceDetails.chunking_strategy !==
                      'TOKEN_LIMIT_CHUNK' && (
                      <div className="tokens-div">
                        Progress bar is only visible when Chunking Stratergy is
                        Token Chunking
                      </div>
                    )}

                  {!isEditMode && chunkingStrategy !== 'TOKEN_LIMIT_CHUNK' && (
                    <div className="tokens-div">
                      Progress bar is only visible when Chunking Stratergy is
                      Token Chunking
                    </div>
                  )}
                  {isEditMode &&
                    instanceDetails.chunking_strategy ===
                      'TOKEN_LIMIT_CHUNK' && (
                      <div className="tokens-div">
                        {((filetokencount + tokencount) /
                          tokelimit?.tokenSize) *
                          100 >=
                          100 && (
                          <div className="token-limit-exceeded-warning-msg">
                            {' '}
                            Token limit for {selectedModel} exceeded. To fix
                            this issue, please consider changing the LLM model
                            or reducing the knowledge IDs used in the above
                            template
                          </div>
                        )}
                        <div className="token-counter-bar-wrapper">
                          <div className="token-display">
                            Tokens Used {filetokencount + tokencount}/
                            {tokelimit.tokenSize}
                          </div>
                          <ProgressBar
                            className="token-count-progress"
                            now={
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                              100
                            }
                            variant={getColor(
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                                100
                            )}
                          />
                        </div>
                      </div>
                    )}
                  {!isEditMode &&
                    chunkingStrategy === 'TOKEN_LIMIT_CHUNK' &&
                    !step3 && (
                      <div className="tokens-div">
                        Progress bar is only visible when Chunking Stratergy is
                        Token Chunking
                      </div>
                    )}
                  {!isEditMode &&
                    chunkingStrategy === 'TOKEN_LIMIT_CHUNK' &&
                    step3 && (
                      <div className="tokens-div">
                        {((filetokencount + tokencount) / tokelimit.tokenSize) *
                          100 >=
                          100 && (
                          <div className="token-limit-exceeded-warning-msg">
                            {' '}
                            Token limit for ${selectedModel} exceeded. To fix
                            this issue, please consider changing the LLM model
                            or reducing the knowledge IDs used in the above
                            template
                          </div>
                        )}
                        <div className="token-counter-bar-wrapper">
                          <div className="token-display">
                            Tokens Used {filetokencount + tokencount}/
                            {tokelimit.tokenSize}
                          </div>
                          <ProgressBar
                            className="token-count-progress"
                            now={
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                              100
                            }
                            variant={getColor(
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                                100
                            )}
                          />
                        </div>
                      </div>
                    )}
                </div>
                {isUploadtemplate && (
                  <Col>
                    <Row>
                      <Form.Group
                        className="file-input"
                        controlId="exampleForm.fileInput"
                      >
                        <Form.Label>Upload document(s)</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          accept={
                            currentApp?.name === 'Status Report Generator'
                              ? '.ppt, .pptx, .docx'
                              : '.csv, .xlsx, .pdf, .docx, .txt'
                          }
                          onChange={handleFileChange}
                          ref={filesSelectedForUploadRef}
                          disabled={isEditMode || isBackClicked}
                        />
                      </Form.Group>
                      <div>
                        <Form.Text>Formats supported: docx & ppt</Form.Text>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <Form.Text>
                          Note: If no templates are added then default templates
                          will be added from backend.
                        </Form.Text>
                      </div>
                    </Row>
                    <Row>
                      {showFileUploadSuccessMsg && (
                        <Alert variant="success">
                          File uploaded successfully
                        </Alert>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="secondary"
                          onClick={() => handleUploadInstanceDoc()}
                          className="secondary-btn-style upload-secondary"
                          disabled={isEditMode || isBackClicked}
                        >
                          Upload File
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
                {step3 && (
                  <div className="template-field-wrapper ">
                    <Form.Label className="label-text">
                      {KNOWLEDGE_IDS}
                    </Form.Label>
                    <div className="knowledgeId-list-wraper">
                      {instanceDetails ? (
                        <KnowledgeTable
                          data={instanceDetails.knowledgeIds}
                          expandedRows={expandedRows}
                          toggleRowExpansion={toggleRowExpansion}
                          showDelete={false}
                        />
                      ) : (
                        <KnowledgeTable
                          data={knowledgeIDs}
                          expandedRows={expandedRows}
                          toggleRowExpansion={toggleRowExpansion}
                          showDelete={false}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Form.Group>

              <div className="two-button-container">
                <Button
                  variant="primary"
                  onClick={() => prevStep()}
                  className="secondary-btn-style modal-button"
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={createTemplate}
                  className="modal-button"
                  disabled={filetokencount + tokencount > tokelimit.tokenSize}
                >
                  Finish
                </Button>
              </div>
            </Container>
          )
        )
        //code for llm guard
        // : (
        //   ((!step3 && currentStep === 3) ||
        //     (!step2 && currentStep === 3) ||
        //     (currentStep === 4 && step4)) && (
        //     <>
        //       <TrusthWorthyLlmForm
        //         instanceData={instanceData}
        //         nextStep={nextStep}
        //         prevStep={prevStep}
        //         setShowNewInstanceModal={setShowNewInstanceModal}
        //       />
        //     </>
        //   )
        // )
      }
    </div>
  );
};

export default InstanceGenerator;
