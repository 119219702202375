/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { authenticateUser, setAuthToken } from './auth';
import { useNavigate } from 'react-router-dom';
import GettingStartedModal from './components/Modals/GetStartedModal';
export const PrivateRoute = ({ children }: any) => {
  const [authenticated, setAuthenticated] = useState(
    !!sessionStorage.getItem('jwtToken')
  );
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const hasUserLoggedInBefore = localStorage.getItem('hasUserLoggedInBefore');

  useEffect(() => {
    if (!hasUserLoggedInBefore || hasUserLoggedInBefore !== 'true') {
      setShowModal(true);
    } else {
      const token = sessionStorage.getItem('jwtToken') ? true : false;
      if (!token) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
          // setAuthenticated(false);
          authenticateUser();
        } else {
          setAuthToken(token);
          setAuthenticated(true);
          navigate('/');
        }
      } else setAuthenticated(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('hasUserLoggedInBefore', 'true');
    authenticateUser();
    setShowModal(false);
  };

  return showModal ? (
    <GettingStartedModal show={showModal} handleClose={handleCloseModal} />
  ) : authenticated ? (
    <>{children}</>
  ) : null;
};
