/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Instance } from '../../interfaces';
import { Alert, Badge, Button, Form, Modal, Spinner } from 'react-bootstrap';
import InstanceGenerator from '../InstanceGenerator';
import UserContext from '../../context/UserContext';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import './SelectInstance.css';
import { FaTrashAlt } from 'react-icons/fa';
import { SetStateFunction } from '../../types';

type SelectInstanceProps = {
  projectId: any;
  currentApp: any;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  chatbox?: any;
  isUploadtemplate?: boolean;
  outputData?: any;
  setOutputData?: any;
  setUserInput?: any;
  setShowDownload?: SetStateFunction<boolean>;
  setUploadedFiles?: any;
  instanceDetails?: any;
  setInstanceDetails?: any;
};
type DeleteErrorToast = {
  show: boolean;
  message: string;
};

const SelectInstance: React.FC<SelectInstanceProps> = ({
  projectId,
  step2 = true,
  step3 = true,
  step4 = true,
  currentApp,
  chatbox,
  isUploadtemplate,
  outputData,
  setOutputData,
  setUserInput,
  setShowDownload,
  setUploadedFiles,
  instanceDetails,
  setInstanceDetails,
}) => {
  const [instances, setInstances] = useState<Instance[]>([]);
  const [showNewInstanceModal, setShowNewInstanceModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [instanceDetails, setInstanceDetails] = useState<string>('');
  const [showClearChatWarning, setShowClearChatWarning] =
    useState<boolean>(false);
  const [newSelectedInstance, setNewSelectedInstance] = useState<any>(null);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isDeleteResponse, setIsDeleteResponse] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isDeleteFailed, setIsDeleteFailed] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<DeleteErrorToast>({
    show: false,
    message: '',
  });
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    setSelectedInstance: () => {},
    showToast: false,
    setShowToast: () => {},
    showErrMsg: '',
    chatContents: [],
    setLlmCloud: () => {},
    llmCloud: '',
    setCloudProvider: () => {},
    filesSelectedForUploadRef: null,
    llmGuardOption: '',
    setLlmGuardOption: () => {},
  };

  const {
    selectedInstance,
    setSelectedInstance,
    showToast,
    setShowToast,
    showErrMsg,
    chatContents,
    setLlmCloud,
    setCloudProvider,
    filesSelectedForUploadRef,
    llmGuardOption,
    setLlmGuardOption,
  } = userContext;

  const fetchInstances = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/project/${projectId}/application/${currentApp?.id}`
      )
      .then((response) => {
        const data = response.data;
        const sorteddata = data.response.sort((a: any, b: any) => {
          if (Number(a.id) > Number(b.id)) {
            return 1;
          }
          if (Number(a.id) < Number(b.id)) {
            return -1;
          }
          return 0;
        });
        setInstances(sorteddata);
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  useEffect(() => {
    fetchInstances();
    if (selectedInstance) {
      getInstanceData(selectedInstance);
    }
  }, []);

  const handleInstanceChange = (value: string, forceClear: boolean = false) => {
    if (!forceClear && chatContents && chatContents.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: handleInstanceChange,
        prop: value,
      });
    } else if (outputData && outputData?.length !== 0) {
      setNewSelectedInstance(value);
      setShowClearChatWarning(true);
    } else {
      setSelectedInstance(value);
      if (value) {
        getInstanceData(value);
      }
      setOutputData('');
      setShowToast(false);
    }
    if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
      filesSelectedForUploadRef.current.value = '';
    }
  };

  const handleClearChat = () => {
    setOutputData('');
    if (setUserInput) {
      setUserInput('');
    }
    if (setShowDownload) {
      setShowDownload(false);
    }
    if (setUploadedFiles) {
      setUploadedFiles(null);
    }
    setSelectedInstance(newSelectedInstance);
    setShowClearChatWarning(false);
    getInstanceData(newSelectedInstance);
  };
  const handleCancelClearChat = () => {
    setShowClearChatWarning(false);
  };

  const nextStep = () => {
    if (currentStep === 1) {
      if (currentApp?.name === 'Change Communicator') {
        fetchInstances();
      }
      setCurrentStep(currentStep + 1);
      setIsBackClicked(false);
    }
    if (currentStep === 2) {
      setIsBackClicked(false);
      setCurrentStep(currentStep + 1);
      fetchInstances();
    }
    if (currentStep === 3) {
      fetchInstances();
      setIsBackClicked(false);
      // setShowNewInstanceModal(false);
      setCurrentStep(currentStep + 1);
      if (selectedInstance) {
        getInstanceData(selectedInstance);
      }
    }
    //code for llm guard
    if (currentStep === 4) {
      fetchInstances();
      setIsBackClicked(false);
      if (selectedInstance) {
        getInstanceData(selectedInstance);
      }
      setShowNewInstanceModal(false);
    }
  };

  const prevStep = () => {
    if (currentStep === 2) {
      setCurrentStep(currentStep - 1);
      getInstanceData(selectedInstance);
      fetchInstances();
      setIsBackClicked(true);
    }
    if (currentStep === 3) {
      getInstanceData(selectedInstance);
      fetchInstances();
      setCurrentStep(currentStep - 1);
      setIsBackClicked(true);
    }
    //code for llm guard
    if (currentStep === 4) {
      getInstanceData(selectedInstance);
      fetchInstances();
      setCurrentStep(currentStep - 1);
      setIsBackClicked(true);
    }
  };

  const openNewInstanceModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (chatContents && chatContents.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: openNewInstanceModal,
      });
      setInstanceDetails('');
    } else if (outputData && outputData?.length !== 0) {
      setShowClearChatWarning(true);
      setInstanceDetails('');
    } else {
      setCurrentStep(1);
      setShowNewInstanceModal(true);
      setIsEditMode(false);
      setInstanceDetails('');
      setShowToast(false);
      setCloudProvider('azure');
    }
  };

  const openEditInstanceModal = () => {
    setCurrentStep(1);
    setShowNewInstanceModal(true);
    setIsEditMode(true);
    setShowToast(false);
    if (selectedInstance) {
      getInstanceData(selectedInstance);
    }
  };

  const getInstanceData = (instanceId: any) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/${instanceId}`
      )
      .then((response) => {
        const data = response.data;
        setInstanceDetails(data.response);
        setLlmCloud(data.response.cloudEnvironment);
        setLlmGuardOption(data.response.llmGuard ? 'Yes' : 'No');
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  const handleDelete = async () => {
    setShowDeletePopup(false);
    setIsDeleteResponse(false);
    setIsDeleteLoading(true);
    setIsDeleteFailed(false);
    clearTimeout((window as any).deleteFailedTimeout);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/delete-instance/${selectedInstance}`
      );
      if (response.status === 200) {
        setIsDeleteLoading(false);
        setIsDeleteResponse(true);
        fetchInstances();
        setSelectedInstance('');
        setInstanceDetails('');
        if (setOutputData) {
          setOutputData('');
        }
        if (setUserInput) {
          setUserInput('');
        }
        if (setShowDownload) {
          setShowDownload(false);
        }
        if (setUploadedFiles) {
          setUploadedFiles(null);
        }
        if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
          filesSelectedForUploadRef.current.value = '';
        }
        setDeleteError({ show: false, message: '' });
      } else {
        console.error('Failed to delete instance');
        setIsDeleteLoading(false);
        setIsDeleteFailed(true);
        (window as any).deleteFailedTimeout = setTimeout(() => {
          setIsDeleteFailed(false);
        }, 4000);
      }
    } catch (error: any) {
      console.error('Error:', error);
      setDeleteError({
        show: true,
        message: error?.response?.data?.error?.errorMessage,
      });
      setIsDeleteLoading(false);
      setIsDeleteFailed(true);
      (window as any).deleteFailedTimeout = setTimeout(() => {
        setIsDeleteFailed(false);
      }, 4000);
    }
  };

  const canceldeletePopup = () => {
    setShowDeletePopup(false);
    setDeleteError({ show: false, message: '' });
  };

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsDeleteResponse(false);
    }, 4000);

    return () => clearTimeout(removeSuccessMessage);
  }, [isDeleteResponse]);

  const deleteModal = () => {
    return (
      <div className="clear-chat-warning-container">
        <Modal
          show={showDeletePopup}
          onHide={canceldeletePopup}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Instance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-warning">
              <p>
                This action will delete the selected Instance:{' '}
                {instanceDetails.name}. Are you sure you want to delete it?
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="secondary" onClick={canceldeletePopup}>
              Cancel
            </Button>
          </Modal.Footer>
          {deleteError.show && (
            <div
              style={{
                paddingTop: '40px',
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Alert
                variant="danger"
                onClose={() => setDeleteError({ show: false, message: '' })}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                  {deleteError.message
                    ? deleteError.message
                    : `Something went wrong. Please try again`}
                </p>
              </Alert>
            </div>
          )}
        </Modal>
      </div>
    );
  };

  return (
    <>
      <Form.Group className="user-input" controlId="exampleForm.instancePicker">
        <Form.Label>Select an Instance</Form.Label>
        <div className="icon-dropdown-wrapper">
          <Form.Select
            aria-label="Select an instance"
            className="instance-picker"
            value={selectedInstance}
            onChange={(evt) => {
              handleInstanceChange(evt?.target?.value);
            }}
          >
            <option value="">Select an instance</option>
            {instances.map((instance) => (
              <option key={instance.id} value={instance.id}>
                {`${instance?.name} | ${instance.cloudEnvironment.toUpperCase()}`}
              </option>
            ))}
          </Form.Select>
          <Button
            className="icon-edit-button"
            onClick={openEditInstanceModal}
            disabled={!selectedInstance}
          >
            <FaEdit className="icon-edit" />
          </Button>
          <Button
            className="knowledge-delete-button"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowDeletePopup(true)}
            disabled={!selectedInstance}
          >
            <FaTrashAlt className="knowledge-icon-delete" />
          </Button>
        </div>
        {showDeletePopup && deleteModal()}
        {isDeleteLoading && (
          <div className="upload-spinner">
            <Spinner animation="border" size="sm" /> Deleting..
          </div>
        )}
        {isDeleteResponse && (
          <div className="upload-msg">
            <Badge bg="danger">Delete Successful</Badge>
          </div>
        )}
        {isDeleteFailed && (
          <div className="upload-msg">
            <Badge bg="danger">Delete Failed</Badge>
          </div>
        )}
        <Form.Label className="create-new-instance-link">
          <a href="javascript:void(0);" onClick={openNewInstanceModal}>
            Create New Instance
          </a>
        </Form.Label>
      </Form.Group>

      {showNewInstanceModal ? (
        <Modal
          show={showNewInstanceModal}
          onHide={() => {
            setShowNewInstanceModal(false);
            fetchInstances();
            getInstanceData(selectedInstance);
            setIsBackClicked(false);
          }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            {isEditMode ? 'Edit Instance' : 'Create New Instance'}
          </Modal.Header>
          <Modal.Body>
            <InstanceGenerator
              currentStep={currentStep}
              projectId={projectId}
              currentAppId={currentApp?.id}
              nextStep={nextStep}
              prevStep={prevStep}
              isBackClicked={isBackClicked}
              setShowNewInstanceModal={setShowNewInstanceModal}
              step2={llmGuardOption === 'Yes' ? true : false}
              step3={step3}
              step4={step4}
              isUploadtemplate={isUploadtemplate}
              isEditMode={isEditMode}
              instanceDetails={instanceDetails}
              selectedInstance={selectedInstance}
              getInstanceData={getInstanceData}
            />
          </Modal.Body>
          {showToast && (
            <div
              style={{
                paddingTop: '40px',
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Alert
                variant="danger"
                onClose={() => setShowToast(false)}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                  {showErrMsg
                    ? showErrMsg
                    : `Something went wrong. Please try again`}
                </p>
              </Alert>
            </div>
          )}
        </Modal>
      ) : null}
      {showClearChatWarning && (
        <div className="clear-chat-warning-container">
          <Modal
            show={showClearChatWarning}
            onHide={handleCancelClearChat}
            backdrop="static"
            keyboard={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Clear Chat?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This action will clear the chat history and create a new
                session. Are you sure you want to clear the chat?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClearChat}>
                Clear
              </Button>
              <Button variant="secondary" onClick={handleCancelClearChat}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {showDeletePopup && deleteModal()}
    </>
  );
};

export default SelectInstance;
