export const azure_llm_models_options = [
  ['GPT- 4 TURBO 128K - $$', 'gpt-4-turbo'],
  ['GPT-4o 128K', 'gpt-4o'],
  ['GPT- 4 32K - $$$', 'gpt4-32k'],
  ['GPT- 3.5 16K - $', 'gpt35-16k'],
];

//Feedback Component Contants
export const ENTER_FEEDBACK_PLACEHOLDER = 'Please enter feedback...';
export const FEEDBACK_THANK_YOU_MSG = 'Thank you for your feedback!';
export const WAS_THIS_HELPFUL = 'Was this helpful?';
export const FEEDBACK_ERROR =
  'Sorry we encountered some issue in sending your feedback!';
export const SUBMIT = 'Submit';
export const THUMBS_UP_ICON = '👍';
export const THUMBS_DOWN_ICON = '👎';

//CaseCopiotConstants
export const UPLOAD_SUCCESS = 'Upload Successful';
export const OPTION_SELECT = 'Choose an option';
export const DATA_FILE = 'Data File';
export const FORMATS_SUPPORTED = 'Formats supported';
export const UPLOAD = 'Uploading';
export const DICTIONARY_FILE = 'Dictionary File';
export const QUESTION = 'Enter your question';
export const SNAP_MESSAGE = 'Something went wrong. Please try again';
export const ERROR_MESSAGE = 'Oh snap! You got an error!';
export const ADVANCED_ERROR_MESSAGE =
  ' Something went wrong with your Advanced settings. Please make sure required fields like query params are not blank.';
export const REFRESHING = 'Refreshing...';

//InstanceGeneratorConstants
export const KNOWLEDGE_IDS = 'Knowledge IDs';

//LlmFineTuneDatasetGeneratorConstants:
export const CREATE_DATASET = 'Create a dataset from document';
export const UPLOAD_DATASET = 'Upload dataset file (CSV/XLSX) to upsample';

// Application constants:
export const USER_STORY_GENERATOR = 'User Story Generator';
//VisionqaFeedback constants:
export const Feed_Back_Message = 'Thank you for the feedback!';

//DocumentOCR constants:
export const File_Format = '.pdf';
export const File_Formats = '.pdf, .jpeg, .jpg, .png';

//tab title
export const UPLOAD_TAB = 'Upload';
export const DOWNLOAD_DOCUMENTATION = 'Download Documentation';

//llm models
export const GPT_4_TURBO = 'gpt-4-turbo';
export const GPT_4O = 'gpt-4o';
export const GPT4_32K = 'gpt4-32k';
export const GPT4_8K = 'gpt4-8k';
export const GPT35_16K = 'gpt35-16k';
export const GPT35_4K = 'gpt35-4k';

//llm guard Scanner types
export const Anonymize = 'Anonymize';
export const BanCode = 'BanCode';
export const BanCompetitors = 'BanCompetitors';
export const BanSubstrings = 'BanSubstrings';
export const BanTopics = 'BanTopics';
export const Code = 'Code';
export const Gibberish = 'Gibberish';
export const InvisibleText = 'InvisibleText';
export const Language = 'Language';
export const PromptInjection = 'PromptInjection';
export const Regex = 'Regex';
export const TokenLimit = 'TokenLimit';
export const Secrets = 'Secrets';
export const Sentiment = 'Sentiment';
export const Toxicity = 'Toxicity';
