import React from 'react';
import axios from 'axios';

interface Chat {
  role: string;
  content: string;
}

type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;
const llmGuardSubmit = async ({
  requestBody,
  setShowToast,
  setIsLoading,
  setShowErrMsg,
  llmCloud,
  setActivityId,
  setSanitizedPrompt,
}: {
  requestBody: any;
  setShowToast?: any;
  setIsLoading: any;
  setShowErrMsg?: SetStateFunction<string>;
  llmCloud?: string;
  setActivityId?: any;
  setSanitizedPrompt?: any;
}) => {
  const token = sessionStorage.getItem('jwtToken');

  let apiUrl = '';
  setActivityId('');

  if (llmCloud === 'azure') {
    if (requestBody?.advance_search_params) {
      apiUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/advance/cached`;
    } else if (requestBody?.messages?.length) {
      apiUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/messages`;
    } else {
      apiUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt`;
    }
  }

  if (requestBody?.messages?.length) {
    requestBody.messages = requestBody?.messages?.filter(
      (message: Chat, index: number) =>
        index === 0 || requestBody.messages.length - index < 4
    );
  }

  try {
    setIsLoading(true);
    const response = await axios.post(apiUrl, requestBody);
    setIsLoading(false);
    setActivityId((response?.headers as any)?.get('x-activityid'));
    setSanitizedPrompt(response.data?.sanitized_prompt);
    return response.data.response;
  } catch (error) {
    console.error('axios error:', error);
    setShowErrMsg?.('There is a internal server error. Please try again.');
    setShowToast?.(true);
    setIsLoading(false);
    return null;
  }
};
export default llmGuardSubmit;
