/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';

import UserStoryIcon from '../../assets/app-icons/1.svg';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import Card from 'react-bootstrap/Card';
import { Link, useParams } from 'react-router-dom';
import { App, IconMap } from '../../interfaces';
import Loader from '../../components/Loader';
import axios from 'axios';
import './Project.css';
import UserContext from '../../context/UserContext';
const ApplicationTitle = 'Applications in Playground';
const ProjectCmp = () => {
  const [apps, setApps] = useState<App[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { projectId } = useParams();
  const userContext = useContext(UserContext) || {
    setChatContents: () => [],
    setSelectedInstance: () => {},
    setUserInput: () => {},
    setOutputData: () => {},
  };
  const { setChatContents, setSelectedInstance, setUserInput, setOutputData } =
    userContext;

  const fetchApps = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/apps/project/${projectId}`
      )
      .then((response) => {
        const data = response.data;
        data.response = data.response.map((app: App) => ({
          ...app,
          url: app.name.toLowerCase().replace(/\s/g, '-'),
        }));
        setApps(data.response);
        sessionStorage.setItem('apps', JSON.stringify(data.response));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Error:', err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchApps();
  }, []);

  const getIcons: IconMap = {
    'User Story Generator': UserStoryIcon,
  };

  const handleApplicationStateRefresh = () => {
    setChatContents([]);
    setSelectedInstance(null);
    setUserInput('');
    setOutputData('');
  };

  const sortAppsById = (apps: any) => {
    return apps.sort(
      (app1: any, app2: any) => parseInt(app1.id, 10) - parseInt(app2.id, 10)
    );
  };

  const groupedApps = apps.reduce((groups: any, app) => {
    const { applicationGroup, ...rest } = app;
    if (!groups[applicationGroup]) {
      groups[applicationGroup] = [];
    }
    groups[applicationGroup].push(rest);
    return groups;
  }, {});

  Object.keys(groupedApps).forEach((group) => {
    groupedApps[group] = sortAppsById(groupedApps[group]);
  });

  return (
    <>
      <div className="title">{ApplicationTitle}</div>
      <div className="back-button-container applications-back">
        <Link to={`/`}>
          <BackIcon /> Back
        </Link>
        {apps.length > 0 && (
          <Link to={`/project/${projectId}/activity-history`}>
            <button className="activity-button">Activity History</button>
          </Link>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : apps.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <h1>You do not have access to any of the Applications.</h1>
          <p>
            Please reach out to your sector’s Nerve Center for more information
            on gaining access.
          </p>
        </div>
      ) : (
        <div className="card-container">
          {Object.keys(groupedApps).map((group, index) => (
            <div key={index}>
              <div className="group-title">Engines for {group}</div>
              <div className="card-container">
                {groupedApps[group].map((app: any) => (
                  <Link
                    to={`/project/${projectId}/${app.url}`}
                    className="card-link"
                    key={app.id}
                  >
                    <Card
                      className="application-card"
                      onClick={handleApplicationStateRefresh}
                    >
                      <Card.Body>
                        <div className="application-card-content">
                          <img src={getIcons[app.type]} alt={app.name} />
                          <h5 className="title-text">{app.name}</h5>
                          <div className="description-text">
                            {app.description}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProjectCmp;
