/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Appl } from '../../interfaces';
import { ApplicationDetails } from '../../types';

import './Application.css';
import UserStoryGenerator from '../UserStoryGenerator';
const Application = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);

  const applicationList: Record<string, ApplicationDetails> = {
    'User Story Generator': {
      pageComponent: UserStoryGenerator,
    },
  };

  const getApplicationJsx = (type: string) => {
    const component = applicationList[type]?.pageComponent;
    if (component) {
      return React.createElement(component, {
        currentApp: currentApp,
        projectId: projectId,
      });
    }
  };
  return (
    <div className="application-page-container">
      {currentApp?.type && getApplicationJsx(currentApp?.type)}
    </div>
  );
};

export default Application;
