import React, { useState, useRef } from 'react';
import UserContext from './UserContext';
import { Chat, IAdvancedSettings } from '../interfaces';

const UserContextProvider = ({ children }: any) => {
  const [selectedInstance, setSelectedInstance] = useState<any>(null);
  const [useAdvancedSettings, setUseAdvancedSettings] = useState<boolean>(
    JSON.parse(localStorage.getItem('useAdvancedSettings') ?? 'null') ?? false
  );

  const [advancedSettings, setAdvancedSettings] = useState<IAdvancedSettings>(
    JSON.parse(localStorage.getItem('advancedSettings') ?? 'null') ?? {
      mode: 'autocut',
      autocut: 1,
      query_param: undefined,
      fusion_type: undefined,
    }
  );
  const [localSelectedModel, setLocalSelectedModel] = useState(() => {
    const storedModel = localStorage.getItem('selectedModel');
    return storedModel === 'Azure' ? storedModel : 'Azure';
  });
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [chatContents, setChatContents] = useState<Chat[]>([]);
  const [userInput, setUserInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState<boolean>(false);
  const [showAdvancedSettingsAlert, setShowAdvancedSettingsAlert] =
    useState<boolean>(false);
  const [isActivityActive, setIsActivityActive] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [outputData, setOutputData] = useState<string>('');
  const [queryExpansion, setQueryExpansion] = useState<boolean>(false);
  const [llmCloud, setLlmCloud] = useState<string>('');
  const [cloudProvider, setCloudProvider] = useState('azure');
  const filesSelectedForUploadRef = useRef<HTMLInputElement>(null);
  const [llmGuardOption, setLlmGuardOption] = useState('');
  return (
    <UserContext.Provider
      value={{
        selectedInstance,
        setSelectedInstance,
        useAdvancedSettings,
        setUseAdvancedSettings,
        advancedSettings,
        setAdvancedSettings,
        showToast,
        setShowToast,
        showErrMsg,
        setShowErrMsg,
        chatContents,
        setChatContents,
        userInput,
        setUserInput,
        showAdvancedSettingsAlert,
        setShowAdvancedSettingsAlert,
        showAdvancedSettings,
        setShowAdvancedSettings,
        isLoading,
        setIsLoading,
        localSelectedModel,
        setLocalSelectedModel,
        isActivityActive,
        setIsActivityActive,
        isRefreshing,
        setIsRefreshing,
        setOutputData,
        outputData,
        queryExpansion,
        setQueryExpansion,
        llmCloud,
        setLlmCloud,
        cloudProvider,
        setCloudProvider,
        filesSelectedForUploadRef,
        llmGuardOption,
        setLlmGuardOption,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
