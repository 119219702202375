import React from 'react';
import { Chat, IAdvancedSettings } from '../interfaces';
import { SetStateFunction } from '../types';

export interface UserContextType {
  selectedInstance: any;
  setSelectedInstance: React.Dispatch<any>;
  useAdvancedSettings: boolean;
  setUseAdvancedSettings: React.Dispatch<boolean>;
  setAdvancedSettings: SetStateFunction<IAdvancedSettings>;
  advancedSettings: IAdvancedSettings;
  showToast: boolean;
  setShowToast: React.Dispatch<boolean>;
  showErrMsg: string;
  setShowErrMsg: React.Dispatch<string>;
  chatContents: Chat[];
  setChatContents: SetStateFunction<Chat[]>;
  userInput: string;
  setUserInput: (userInput: string) => void;
  setShowAdvancedSettingsAlert: SetStateFunction<boolean>;
  showAdvancedSettingsAlert: boolean;
  showAdvancedSettings: boolean;
  setShowAdvancedSettings: SetStateFunction<boolean>;
  isLoading: boolean;
  setIsLoading: SetStateFunction<boolean>;
  localSelectedModel: string;
  setLocalSelectedModel: SetStateFunction<string>;
  isActivityActive: boolean;
  setIsActivityActive: SetStateFunction<boolean>;
  isRefreshing: boolean;
  setIsRefreshing: SetStateFunction<boolean>;
  setOutputData: SetStateFunction<string>;
  outputData: string;
  queryExpansion: boolean;
  setQueryExpansion: SetStateFunction<boolean>;
  llmCloud: string;
  setLlmCloud: SetStateFunction<string>;
  cloudProvider: string;
  setCloudProvider: SetStateFunction<string>;
  filesSelectedForUploadRef: React.RefObject<HTMLInputElement>;
  llmGuardOption: string;
  setLlmGuardOption: SetStateFunction<string>;
}

const UserContext = React.createContext<UserContextType | null>(null);

export default UserContext;
