import Card from 'react-bootstrap/Card';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MichiganLogo } from '../../assets/dumy-michigan-logo.svg';
import { Project } from '../../interfaces';
import Loader from '../../components/Loader';
import './Home.css';
import { Alert } from 'react-bootstrap';
import axios from 'axios';

const Home = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<string>('');

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/projects/all`
      );

      const data = response.data;
      setShowToast(false);
      setProjects(data.response);
      sessionStorage.setItem('projects', JSON.stringify(data.response));
      setIsLoading(false);
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error?.response?.data || error?.message
      );
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setShowToast(true);
      setShowErrMsg(err?.errorMessage);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className="home-page-container">
      <div className="title-container">
        <h1 className="title">Your Projects</h1>
      </div>
      {isLoading ? (
        <Loader />
      ) : projects.length === 0 ? (
        <div style={{ textAlign: 'center' }}>
          <h1>You do not have access to any of the projects.</h1>
          <p>
            Please reach out to your sector’s Nerve Center for more information
            on gaining access.
          </p>
        </div>
      ) : (
        <div className="card-container">
          {projects.length > 0 &&
            projects.map((project) => (
              <Link
                to={`/project/${project.id.toString()}`}
                className="card-link home-card-link"
                key={project.id}
              >
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <div>
                        <MichiganLogo />
                      </div>
                      <h2 className="card-title-text project-text">
                        {project.name}
                      </h2>
                    </Card.Title>
                    {/* <Card.Text>
                            </Card.Text> */}
                  </Card.Body>
                </Card>
              </Link>
            ))}

          {showToast && (
            <div
              style={{
                paddingTop: '40px',
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Alert
                variant="danger"
                onClose={() => setShowToast(false)}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                  {showErrMsg
                    ? showErrMsg
                    : `Something went wrong. Please try again`}
                </p>
              </Alert>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
