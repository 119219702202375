/* eslint-disable react/display-name */
import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { AiOutlineSend } from 'react-icons/ai';
import { FaRegStopCircle } from 'react-icons/fa';
import { RiChatDeleteLine } from 'react-icons/ri';
import { Chat, Image } from '../../interfaces';
import DownloadButton from '../DownloadButton';
import ContentLoader from '../Loader/contentLoader';
import './Chatbox.css';
import { MouseEventHandler } from 'react';
import UserContext from '../../context/UserContext';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Chatbox = forwardRef(
  (
    {
      submitPrompt,
      downloadButtonOptions,
      chatClearCallback,
      selectedModel,
      isMultiplePrompts,
      feedBackProperties,
      useMarkdownFormat,
      handleAbortStream,
    }: {
      submitPrompt: MouseEventHandler<HTMLButtonElement>;
      downloadButtonOptions?: any;
      chatClearCallback: Function;
      selectedModel?: any;
      currentAppName?: any;
      isMultiplePrompts?: Boolean;
      feedBackProperties?: any;
      useMarkdownFormat?: boolean;
      handleAbortStream?: MouseEventHandler<HTMLButtonElement>;
    },
    ref: any
  ) => {
    const chatWrapper = useRef<null | HTMLPreElement>(null);
    const { showDownload, selectedInstance } = downloadButtonOptions ?? {};
    const [showClearChatWarning, setShowClearChatWarning] =
      useState<boolean>(false);
    const [clearChatCallback, setClearChatCallback] = useState<any>(null);

    const userContext = useContext(UserContext) || {
      useAdvancedSettings: false,
      setUseAdvancedSettings: () => {},
      chatContents: [],
      setChatContents: () => {},
      userInput: '',
      setUserInput: () => {},
      isLoading: false,
      setIsLoading: () => {},
      llmGuardOption: '',
    };

    const {
      useAdvancedSettings,
      setUseAdvancedSettings,
      chatContents,
      setChatContents,
      userInput,
      setUserInput,
      isLoading,
      setIsLoading,
      llmGuardOption,
    } = userContext;

    useEffect(() => {
      chatWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
      chatWrapper?.current?.scrollTo({
        top: chatWrapper?.current?.scrollHeight,
        behavior: 'smooth',
      });
    });

    const initiateChatWarningModal = ({
      callback,
      prop,
    }: {
      callback: Function;
      prop: any;
    }) => {
      setShowClearChatWarning(true);
      setClearChatCallback({ callback, prop });
    };

    const handleAbort = (e: any) => {
      handleAbortStream && handleAbortStream(e);
      setIsLoading(false);
      // setChatContents([]);
    };

    const clearChat = () => {
      setChatContents([]);
      setUserInput('');
      setIsLoading(false);
      chatClearCallback();
      if (clearChatCallback) {
        const { callback, prop } = clearChatCallback;

        callback(prop, true);
      }
      setClearChatCallback(null);
      setShowClearChatWarning(false);
    };

    const cancelClearChat = () => {
      setUseAdvancedSettings(!useAdvancedSettings);
      setShowClearChatWarning(false);
    };

    useImperativeHandle(ref, () => ({
      initiateChatWarningModal,
    }));
    const userInputRef = useRef<HTMLTextAreaElement>(null);
    const [userInputNewLinecount, setUserInputNewLineCount] = useState(0);

    const userInputChange = (event: any) => {
      let pattern = /^[a-zA-Z0-9]*$/;
      if (pattern.test(userInput[userInput.length - 1])) {
        setUserInputNewLineCount(0);
      }
      setUserInput(event?.target?.value);
    };
    const handleEnterAndNewLinecheck = (event: any) => {
      //Enter is pressed and there is userInput and Shiftkey is not pressed query is submitted
      if (userInputRef.current) {
        userInputRef.current.style.height = 'auto';
        userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
      }
      if (event.keyCode === 13 && userInput && !event.shiftKey) {
        submitPrompt(event);
        setUserInputNewLineCount(0);
      }
      //Enter is pressed and userinput is empty and no shiftkey will do nothing
      if (event.keyCode === 13 && !userInput && !event.shiftKey) {
        event.preventDefault();
        setUserInputNewLineCount(0);
        return;
      }
      if (event.keyCode === 13 && event.shiftKey) {
        // Add new line on shift + enter
        if (userInputNewLinecount > 2) {
          event.preventDefault();
          return;
        } else {
          const previousUserInput = userInput + '\n';
          setUserInputNewLineCount(userInputNewLinecount + 1);
          setUserInput(previousUserInput);
        }
      }
    };
    useEffect(() => {
      if (userInputRef.current) {
        userInputRef.current.style.height = 'auto';
        userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
      }
    }, [userInput]);
    return (
      <div>
        <div className="chatbox-container p-2">
          <pre className="chat-wrapper" ref={chatWrapper}>
            {chatContents.map((chat: Chat, index: any) => (
              // <div className={chat?.role === 'user' ? 'text-start' : 'text-end'}>
              <div
                key={index}
                className={
                  chat?.role === 'user'
                    ? 'user-chat individual-chatbox'
                    : 'assistant-chat individual-chatbox'
                }
              >
                <div>
                  <span className="fw-bold capitalize">{`${chat?.role === 'user' ? 'User' : 'GenAIe'}: `}</span>{' '}
                  {chat?.role === 'assistant' && useMarkdownFormat ? (
                    <div className="chatbox-markdown">
                      <Markdown remarkPlugins={[remarkGfm]}>
                        {chat?.content.toString()}
                      </Markdown>
                    </div>
                  ) : (
                    <div>
                      {chat?.content
                        .toString()
                        .split(/\n/)
                        .map((text, i) => {
                          return text.match(/\[.*\]\(.*\)/g) ? (
                            <Markdown key={i}>{text}</Markdown>
                          ) : (
                            <p>{text}</p>
                          );
                        })}
                    </div>
                  )}
                  {chat?.role === 'assistant' && chat?.images && (
                    <Row className="image-rendering">
                      {chat?.images.map((image: Image, index: number) => (
                        <Col key={index} xs={12} md={4} className="mb-3">
                          <div>
                            <img
                              className="img-style"
                              src={image?.url}
                              alt={image?.img_name}
                            />
                            <p className="img-name-style">
                              {decodeURI(image?.img_name)}
                            </p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
                {showDownload && chat?.role === 'assistant' && (
                  <div className="download-button-container">
                    <DownloadButton
                      instanceId={selectedInstance}
                      outputData={chat?.content}
                    />
                  </div>
                )}
              </div>
            ))}
          </pre>

          <Form>
            <Form.Group
              className="chat-user-input"
              controlId="exampleForm.userInput"
            >
              <Form.Control
                as="textarea"
                placeholder="Enter your prompt"
                className="user-prompt-input"
                ref={userInputRef}
                value={userInput}
                rows={1}
                onChange={(e) => userInputChange(e)}
                onKeyDown={(e) => handleEnterAndNewLinecheck(e)}
                draggable={false}
              />
              <div className="user-story-generator-submit-container">
                {isLoading ? (
                  <Button
                    className="user-story-generator-submit primary-btn-style"
                    disabled={true}
                  >
                    <ContentLoader />
                  </Button>
                ) : (
                  <Button
                    className="user-story-generator-submit primary-btn-style"
                    type="submit"
                    onClick={submitPrompt}
                    disabled={!userInput?.length}
                  >
                    <AiOutlineSend />
                  </Button>
                )}
                {isLoading &&
                chatContents &&
                chatContents[chatContents.length - 1].content.length &&
                llmGuardOption === 'No' ? (
                  <Button
                    className="clear-chat-btn"
                    variant="danger"
                    type="button"
                    onClick={handleAbort}
                    disabled={
                      chatContents &&
                      !chatContents[chatContents.length - 1].content.length
                    }
                  >
                    <FaRegStopCircle size="1.25rem" />
                  </Button>
                ) : (
                  <Button
                    className="clear-chat-btn"
                    variant="danger"
                    onClick={() => {
                      setShowClearChatWarning(true);
                    }}
                    disabled={!(userInput?.length || chatContents?.length)}
                  >
                    <RiChatDeleteLine size="1.25rem" />
                  </Button>
                )}
              </div>
            </Form.Group>
          </Form>
        </div>
        {showClearChatWarning && (
          <div className="clear-chat-warning-container">
            <Modal
              show={showClearChatWarning}
              onHide={cancelClearChat}
              backdrop="static"
              keyboard={true}
            >
              <Modal.Header closeButton>
                <Modal.Title>Clear Chat?</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>
                  This action will clear the chat history and create a new
                  session. Are you sure you want to clear the chat?
                </p>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={clearChat}>
                  Clear
                </Button>
                <Button variant="secondary" onClick={cancelClearChat}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    );
  }
);

export default Chatbox;
